import React, { Component } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import withLocation from '../../utilities/withLocation'
import Context from "../Context"
import { colors } from '../../styling/constants'
import firebase from 'gatsby-plugin-firebase'

import './Confirmation.scss'
import './kco.scss'
import { FormattedMessage } from 'react-intl'
import { getFirebaseItem } from '../../utilities/firebaseAnalytics'

let InnerHTML;

const Header = styled.h3`
  color: ${colors.darkGray};
`

class Confirmation extends Component {
  state = {
    loading: false,
    error: false,
    showInnerHTML: false,
    kco: {
      snippet: "",
    }
  }

  componentDidMount() {
    const { sid } = this.props.search
    this.resetLocalStorage()
    if (sid) {
      this.setState({ loading: true })
      const handle = this.shouldHandleOrder(sid)
      this.requestOrder(handle, sid)
    }

    InnerHTML = require("dangerously-set-inner-html").default;
    this.setState({showInnerHTML: true})
  }

  getHandledOrders() {
    const handledOrdersData = localStorage.getItem('kco_handled_orders')
    if (handledOrdersData) {
      try {
        const handledOrders = JSON.parse(handledOrdersData)
        return handledOrders
      } catch(err) {
        return
      }
    } else {
      return
    }
  }

  shouldHandleOrder(sid) {
    const handledOrders = this.getHandledOrders()
    if (handledOrders && handledOrders.includes(sid)) {
      return false
    } else {
      return true
    }
  }

  addOrderToLocalStorage(sid) {
    var handledOrders = this.getHandledOrders()
    if (handledOrders) {
      handledOrders.push(sid)
    } else {
      handledOrders = [sid]
    }
    localStorage.setItem('kco_handled_orders', JSON.stringify(handledOrders))
  }

  registerFirebase(data) {
    const currency = this.props.locale === 'sv' ? 'sek' : this.context.data.currency
    const items = data.order_lines.filter(
      (item) => item.type === 'physical',
    ).map((item) => getFirebaseItem(item.name, item.total_amount, item.reference, item.quantity))
    const firebaseParams = {
      transaction_id: data.order_id,
      currency: currency.toUpperCase(),
      value: data.order_amount / 100,
      tax: data.order_tax_amount / 100,
      shipping: 0,
      coupon: data.merchant_data,
      items: items,
   };
   firebase.analytics().logEvent(firebase.analytics.EventName.PURCHASE, firebaseParams);
  }

  resetLocalStorage() {
    localStorage.removeItem(`kco_order_id_${this.props.locale}`)
    localStorage.removeItem('discount_code')
    this.context.set({ 
      cart: []
    })
  }

  requestOrder(update, sid) {
    axios
      .get(`${process.env.GATSBY_KCO_URL}/acceptOrder`, {
        params: {
          order_id: sid,
          update: update
        }
      })
      .then(response => {
        if (response.status === 200) {
          if (update) {
            this.addOrderToLocalStorage(sid)
            if (typeof fbq === `function`) {
              fbq('track', 'Purchase')
            }
            this.registerFirebase(response.data)
          }
          const kcoSnippet = response.data.html_snippet
          this.setState({
            loading: false,
            kco: {
              ...this.state.kco,
              snippet: kcoSnippet
            }
          })
        } else {
          this.setState({ loading: false, error: true })
        }
    }).catch(error => {
      console.log(error)
      this.setState({ loading: false, error: true})
    })
  }

  render() {
    return (
      <div className="checkout-wrapper">
        <Header>
          <FormattedMessage id="confirmation.thank-you" />
        </Header>
        <div className="order-checkout-wrapper">
          <div className="kco full-width">
            { this.state.error && <p><FormattedMessage id="confirmation.error" /></p> }
            { this.state.showInnerHTML && <InnerHTML html={this.state.kco.snippet} /> }
          </div>
        </div>
      </div>
    )
  }
}

Confirmation.contextType = Context;

export default withLocation(Confirmation)
