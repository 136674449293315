import React, { Component } from 'react'
import Confirmation from '../components/checkout/Confirmation'
import SEO from '../components/seo'
import LocaleManager from '../components/LocaleManager'
import { FormattedMessage } from 'react-intl'

const ConfirmationPage = ({ pageContext: { locale } }) => (
    <LocaleManager locale={locale}>
        <FormattedMessage id="confirmation.title">
            {translation => <SEO title={translation} lang={locale} />}
        </FormattedMessage>
        <div className="content extra-top">
            <Confirmation locale={locale} />
        </div>
    </LocaleManager>
)

export default ConfirmationPage
